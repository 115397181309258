.AppRoot {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    background: var(--background-color);
}

.main-content-wrapper {
    flex-grow: 1;
    min-height: 100vh;
}

.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 68px);
    margin-top: 68px;
    background-color: white;
    z-index: 999; /* 네비게이션 바 위에 표시되도록 z-index 설정 */
    display: flex;
    flex-direction: column;

    & .menu-box {
        display: block;
        width: 100%;

        & > ul {
            box-sizing: border-box;

            & > li {
                display: block;
                border-bottom: 1px solid #e0e0e0;
                position: relative;

                & > a {
                    position: relative;
                    padding: 14px 30px;
                    color: #333;
                    font-size: 16px;
                    display: block;
                    width: 100%;
                    height: 100%;
                    font-weight: 600;
                    box-sizing: border-box;

                    &:not(.no-submenu)::after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        right: 16px;
                        width: 12px;
                        height: 12px;
                        background-position: center;
                        background-size: 100%;
                        background: url('../../assets/ico_menu_m_arw.svg')
                            no-repeat center;
                        transform: translateY(-50%);
                        transition: all 0.3s ease;
                    }
                }

                & > a.active::after {
                    transform: translateY(-50%) rotate(180deg);
                }

                & > ul {
                    position: static;
                    width: 100%;
                    padding: 0;
                    text-align: left;
                    border-top: 1px solid #e0e0e0;
                    border-radius: 0;
                    transform: inherit;
                    background: #f5f5f5;
                    box-sizing: border-box;
                    overflow: hidden;
                    transition: max-height 0.4s ease-in-out;
                    max-height: 0;

                    & > li {
                        & > a {
                            padding: 12px 30px;
                            color: #888;
                            display: block;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 1;
                            letter-spacing: -1.5px;

                            &:hover {
                                font-weight: 900;
                                color: #00c3ff;
                            }
                        }
                    }

                    & > li + li {
                        border-top: 1px solid #e0e0e0;
                    }
                }
            }
        }
    }
    & > a {
        display: block;
        width: 100%;
        padding: 14px 30px;
        margin-left: 0;
        border-radius: 0;
        font-size: 16px;
        font-weight: 800;
        background: linear-gradient(100.22deg, #00c3ff 16.89%, #0081ff 80.03%);
        color: #fff;
        box-sizing: border-box;

        &::before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 8px;
            vertical-align: middle;
            background: url('../../assets/chat_icon.svg') no-repeat center;
            background-size: 100%;
        }
    }
}
