.App {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

input,
textarea {
    outline: none;
    transition: border 0.3s;
}

input:active,
input:focus,
textarea:active,
textarea:focus {
    border: 1px solid #8ed1fc !important;
}
