.footer {
    background: #222;

    & .quick-menu {
        position: fixed;
        z-index: 9990;
        bottom: 30px;
        right: 42px;

        & > ul {
            width: 50px;
            padding: 20px 0;
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border-radius: 54px;
            background: rgba(0, 0, 0, 0.8);
            list-style-type: disc;

            & > li {
                display: block;
                width: 30px;
                height: 30px;
                margin: 0 auto;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;

                & + li {
                    margin-top: 16px;
                }

                & > a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    text-indent: -9999px;
                    text-decoration: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                    color: #000;
                    word-break: break-word;
                }
            }
        }

        & > ul::after {
            display: block;
            content: '';
            clear: both;
        }
    }

    & .footer-wrapper {
        position: relative;
        padding: 60px 0;
        width: 1200px;
        margin: 0 auto;

        & .footer-logo {
            margin-bottom: 24px;

            & > img {
                max-width: 100%;
                height: auto;
                vertical-align: middle;
                border-style: none;
                overflow-clip-margin: content-box;
                overflow: clip;
            }
        }

        & .footer-sns {
            position: absolute;
            z-index: 1;
            top: 60px;
            right: 0;

            & > a {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-right: 22px;
                text-indent: -9999px;
                background-position: center;
                background-size: 100%;
            }
        }

        & .footer-address {
            font-style: normal;

            & > p {
                color: #ccc;
                font-size: 14px;
                line-height: 1.6;

                & > a {
                    display: inline-block;
                    color: #ccc;
                    vertical-align: top;
                }
            }
        }
    }

    & .copy {
        padding: 24px 16px;
        color: #8d8d8d;
        font-size: 14px;
        line-height: 1;
        text-align: center;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
    }
}

@media (max-width: 1199px) {
    .footer {
        & .footer-wrapper {
            width: 100%;
            padding: 60px var(--wrapper-para-tb);
            box-sizing: border-box;

            & .footer-sns {
                right: var(--wrapper-para-tb);
                box-sizing: border-box;
            }
        }
    }
}
@media (max-width: 1024px) {
    .footer {
        padding-bottom: 100px;

        & .quick-menu {
            visibility: visible;
            transform: translateY(0);
            bottom: 20px;
            right: 0;
            width: 100%;
            text-align: center;
            transition: all 0.3s;
            position: fixed;
            z-index: 9990;

            & > ul {
                position: relative;
                display: inline-block;
                width: auto;
                padding: 10px 16px;
                background: rgba(255, 255, 255, 0.1);
                overflow: hidden;

                & > li {
                    display: inline-block;
                    position: relative;
                    width: 42px;
                    height: 42px;
                    margin-right: 16px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-size: 28px;

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(
                            100.22deg,
                            #00c3ff 16.89%,
                            #0081ff 80.03%
                        );
                    }

                    & + li {
                        margin-top: 0;
                    }

                    & > a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        text-indent: -9999px;
                    }
                }
            }

            & > ul::after {
                display: block;
                content: '';
                clear: both;
            }
        }

        & .footer-wrapper {
            padding: 50px var(--wrapper-para-mo) 26px;
            text-align: center;

            & .footer-sns {
                position: static;
                margin-bottom: 16px;
            }
        }
    }
}
@media (max-width: 640px) {
    .footer {
        & .footer-wrapper {
            & .footer-logo {
                width: 130px;
                margin: 0 auto 24px;
                box-sizing: border-box;
            }

            & .footer-address {
                & > p {
                    font-size: 12px;
                }
            }
        }

        & .copy {
            padding: 14px 16px;
            font-size: 10px;
            box-sizing: border-box;
        }
    }
}
