@import './assets/reset.css';

html,
body {
    margin: 0;
    font-family: 'NanumSquareNeo', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* 이미지 드래그 방지 */
    -webkit-user-drag: none;
}

.draggable {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

@font-face {
    font-family: 'NanumSquareNeo';
    src:
        local('NanumSquareNeo'),
        url('./assets/fonts/SquareNeo/NanumSquareNeoTTF-aLt.woff2')
            format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NanumSquareNeo';
    src:
        local('NanumSquareNeo'),
        url('./assets/fonts/SquareNeo/NanumSquareNeoTTF-bRg.woff2')
            format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NanumSquareNeo';
    src:
        local('NanumSquareNeo'),
        url('./assets/fonts/SquareNeo/NanumSquareNeoTTF-cBd.woff2')
            format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NanumSquareNeo';
    src:
        local('NanumSquareNeo'),
        url('./assets/fonts/SquareNeo/NanumSquareNeoTTF-dEb.woff2')
            format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NanumSquareNeo';
    src:
        local('NanumSquareNeo'),
        url('./assets/fonts/SquareNeo/NanumSquareNeoTTF-eHv.woff2')
            format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

:root {
    --primary-color: #1890ff;
    --primary-color-light: #e6f7ff;
    --primary-color-dark: #0050b3;

    --gray-color: #555;
    --green-color: #018f29;
    --point-color: #0196ff;
    --purple-color: #633ad6;
    --blue-color: #0081ff;

    --background-color: #fff;

    --text-black-color: #000;
    --text-white-color: #fff;

    --text-xl: 20px;
    --text-lg: 16px;
    --text-md: 14px;

    --swiper-navigation-size: 44px;

    --wrapper-para-tb: 30px;
    --wrapper-para-mo: 16px;
}
