.nav-main {
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    z-index: 1000;
    transition: all 0.3s ease;
}
.nav-main-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-logo {
    height: 100%;
}
.nav-logo > a {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
}
.nav-logo > a > h1 {
    color: var(--text-white-color);
    font-size: var(--text-xl);
    font-weight: 800;
}

.nav-menu {
    display: flex;
    align-items: center;
    gap: 24px;
    height: 100%;
}
.nav-menu-item {
    display: flex;
    height: 100%;
    gap: 60px;
    margin-right: 60px;
}
.nav-menu-item > li {
    height: 100%;
}
.nav-menu-item > li > a {
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--text-white-color);
    font-size: var(--text-md);
    font-weight: 700;
}
.nav-btn {
    display: inline-flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 44px;
    border: 2px solid
        var(--1, linear-gradient(100deg, #00c3ff 16.89%, #0081ff 80.03%));
    background: var(
        --1,
        linear-gradient(100deg, #00c3ff 16.89%, #0081ff 80.03%)
    );
    cursor: pointer;
    color: var(--text-white-color);
    font-weight: 800;
}
.nav-btn:hover {
    background: var(
        --1,
        linear-gradient(100deg, #33d5ff 16.89%, #3394ff 80.03%)
    );
    border: 2px solid
        var(--1, linear-gradient(100deg, #33d5ff 16.89%, #3394ff 80.03%));
    color: var(--text-white-color);
    font-weight: 800;
}

.nav-menu-item-dropdown {
    position: absolute;
    z-index: 1;
    top: 100%;
    width: 176px;
    padding: 12px 0;
    text-align: left;
    border-radius: 8px;
    background: #fff;
    display: none;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

    transform: translateX(-25%);
}
.nav-menu-item-dropdown a {
    display: block;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -1.5px;
    color: var(--text-black-color);
}
.nav-menu-item-dropdown a:hover {
    color: #00c3ff;
    font-weight: 900;
}
.nav-menu-item:hover .nav-menu-item-dropdown {
    display: block;
}

@media screen and (max-width: 1200px) {
    .nav-main-box {
        width: 100%;
        padding: 0 28px;
    }
}

@media screen and (max-width: 1024px) {
    .nav-main {
        height: 68px;
    }
    .nav-main-box {
        height: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .nav-logo {
        height: 100%;

        padding: 20px 0;
        box-sizing: border-box;
    }

    .nav-menu-item {
        display: none;
    }
    .nav-menu-item > li {
        display: none;
    }
    .nav-menu-item > li > a {
        display: none;
    }
    .nav-menu-item-dropdown {
        display: block;
    }
    .nav-menu-item-dropdown a {
        display: block;
    }
    .nav-menu-item-dropdown a:hover {
        display: block;
    }
    .nav-btn {
        display: none;
    }
    .nav-btn:hover {
        display: none;
    }
    .md-btn {
        width: 36px;
        height: 36px;
        margin-left: 'auto';
        border: none;
        background: none;
        cursor: pointer;
    }
}

@media screen and (max-width: 640px) {
}
